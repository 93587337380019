import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import { SplitText } from "gsap/SplitText";
import barba from '@barba/core';
import Flickity from 'flickity';

gsap.registerPlugin(ScrollTrigger,TextPlugin, SplitText);
window.Alpine = Alpine
Alpine.plugin(collapse)
Alpine.start()

function disableActiveMenuItem() {
    // Select all <a> elements with the class 'active'
    var activeLinks = document.querySelectorAll('a.active');

    // Loop through the NodeList of selected elements
    activeLinks.forEach(function(link) {
        // Add an event listener to each link
        link.addEventListener('click', function(event) {
            // Prevent the default link action (navigation)
            event.preventDefault();
        });
    });
}

function firstLoadPageTransition() {
    var tl = gsap.timeline();

    tl.set(".g-transition", {
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
    });

    tl.to(".g-transition", {
        duration: 0.5,
        clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
        ease: "power3.inOut",
        delay: 0.5
    });

    tl.set(".g-transition", {
        clipPath: "polygon(0% 100%,100% 100%,100% 100%,0% 100%)",
    });

}

function transition() {
    var tl = gsap.timeline();

    tl.to(".g-transition", {
        duration: 0.5,
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        ease: "power3.inOut",
    });

    tl.to(".g-transition", {
        duration: 0.5,
        clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
        ease: "power3.inOut",
        delay: 0.5
    });

    tl.set(".g-transition", {
        clipPath: "polygon(0% 100%,100% 100%,100% 100%,0% 100%)",
    });
}

function contentAnimation() {

    var tl = gsap.timeline();
    tl.to('#logo, ul.menu li', {
        duration: 0.5,
        opacity: 1,
        y: 0,
        stagger: 0.1
    });

    tl.to('.intro-headline', {
        duration: 0.5,
        opacity: 1,
        y: 0,
        stagger: 0.1
    },
    "-=1.2"
    );

    tl.to('.hero-video', {
            duration: 0.8,
            clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
            ease: "power3.inOut",
        },
        "-=1.2"
    );
}

barba.init({
    transitions: [
        {
            async leave() {
                const done = this.async();
                transition();
                await delay(500);
                done();
                disableActiveMenuItem();
                window.scrollTo(0, 0);
                document.body.style.overflow = 'hidden';
            },
            async once() {
                window.scrollTo(0, 0);
                const done = this.async();
                firstLoadPageTransition();
                await delay(1000);
                done();
                disableActiveMenuItem();
                initPage();
            },
            async enter() {
                contentAnimation();
                var vids = document.querySelectorAll(".autoplay-video"); vids.forEach(vid => { var playPromise = vid.play(); if (playPromise !== undefined) { playPromise.then(_ => {}).catch(error => {}); }; });
                document.body.style.overflowY = 'auto';
                document.body.style.overflowX = 'hidden';
            },
            async after() {
                initPage();
                disableActiveMenuItem();
            },
            async afterOnce() {
                contentAnimation();
            }
        },
    ],
});

function delay(n) {
    n = n || 1000;
    return new Promise((done) => {
        setTimeout(() => {
            done();
        }, n);
    });
}

function initPage() {

    var loader = document.getElementById('loader');
    var form = document.querySelector('form');
    var message = document.getElementById('message');
    var submitButton = document.getElementById('submitButton');
    var success = document.getElementById('success');
    var fields = {};

    if(form) {

        form.querySelectorAll('[name]').forEach(function (field) {
            fields[field.name] = field;
        });

        // Displays all error messages and adds 'error' classes to the form fields with
        // failed validation.
        var handleError = function (response) {
            var errors = [];
            for (var key in response) {
                if (!response.hasOwnProperty(key)) continue;
                if (fields.hasOwnProperty(key)) fields[key].classList.add('error');
                Array.prototype.push.call(errors, response[key]);
            }
            message.innerHTML = errors.join('<br>');
        }

        var onload = function (e) {
            setTimeout(function() {
                loader.classList.replace('flex', 'hidden');
                submitButton.classList.toggle('hidden');
                if (e.target.status === 200) {
                    window.scrollBy(0, -200);
                    success.classList.toggle('hidden');
                    form.style.display = 'none';
                } else {
                    handleError(JSON.parse(e.target.response));
                }
            }, 600);
        };

        var submit = function (e) {
            loader.classList.replace('hidden', 'flex');
            submitButton.classList.toggle('hidden');
            message.innerHTML = '';
            e.preventDefault();
            var request = new XMLHttpRequest();
            request.open('POST', e.target.action);
            request.onload = onload;
            request.send(new FormData(e.target));
            // Remove all 'error' classes of a possible previously failed validation.
            for (var key in fields) {
                if (!fields.hasOwnProperty(key)) continue;
                fields[key].classList.remove('error');
            }
        };
        form.addEventListener('submit', submit);
    }

    var carousel = document.querySelector('.carousel');
    triggers();

    if(carousel){

        var flkty = new Flickity( carousel, {
            cellAlign: 'left',
            wrapAround: true,
            pageDots: false,
            prevNextButtons: false,
            on: {
                ready: function() {
                    triggers();
                }
            }
        });

        const slides = gsap.utils.toArray(carousel);
        if (slides.length !== 0) {
            slides.forEach(slide => {
                gsap.to(slide.querySelectorAll('.flickity-cell'), {
                    y: 0,
                    duration: 0.5,
                    opacity: 1,
                    stagger: 0.2,
                    scrollTrigger: {
                        trigger: slides,
                    }
                })
            });
        }

        var nextButtons = document.querySelectorAll('.next');
        nextButtons.forEach(function(button) {
            button.addEventListener('click', function() {
                flkty.next();
            });
        });

        var prevButtons = document.querySelectorAll('.prev');
        prevButtons.forEach(function(button) {
            button.addEventListener('click', function() {
                flkty.previous();
            });
        });

        flkty.cells.forEach(function(cell, i) {
            if (cell.element == flkty.selectedElement) {
                var video = cell.element.querySelector("video");
                if (video) {
                    video.play();
                }
                return;
            }
        });

        flkty.on("change", function() {
            flkty.cells.forEach(function(cell, i) {
                if (cell.element == flkty.selectedElement) {
                    var video = cell.element.querySelector("video");
                    if (video) {
                        video.play();
                    }
                    return;
                }
                var video = cell.element.querySelector("video");
                if (video && !carousel.classList.contains('autoplay')) {
                    video.pause();
                }
            });
        });
    }

}

function triggers() {

    let triggers = ScrollTrigger.getAll();
    triggers.forEach(trigger => {
        trigger.kill();
    });

    const form = document.querySelector('.form');
    if (form) {
        gsap.to(form, {
            duration: 0.5,
            opacity: 1,
            y: 0,
            scrollTrigger: {
                trigger: form,
            }
        });
    }

    const videos = gsap.utils.toArray('.full-video');
    if (videos.length !== 0) {
        videos.forEach(video => {
            gsap.to(video, {
                delay: 0.1,
                duration: 0.8,
                clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                ease: "power3.inOut",
                scrollTrigger: {
                    trigger: video,
                }
            })
        });
    }

    const founder = document.querySelector('.founder');
    if (founder !== 0) {
        gsap.to(founder, {
            delay: 0.1,
            duration: 0.8,
            clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
            ease: "power3.inOut",
            scrollTrigger: {
                trigger: founder,
            }
        })
    }

    const texts = gsap.utils.toArray('p, .text');
    if (texts.length !== 0) {
        texts.forEach(text => {
            gsap.to(text, {
                delay: 0.1,
                y: 0,
                duration: 0.5,
                opacity: 1,
                scrollTrigger: {
                    trigger: text,
                }
            })
        });
    }

    const footerLinks = gsap.utils.toArray('.footer-links div, .footer-links a');
    if (footerLinks.length !== 0) {
        footerLinks.forEach(footerLink => {
            gsap.to(footerLink, {
                delay: 0.1,
                y: 0,
                duration: 0.5,
                opacity: 1,
                scrollTrigger: {
                    trigger: footerLink,
                }
            })
        });
    }

    const headlines = gsap.utils.toArray('.headlines');
    if (headlines.length !== 0) {
        headlines.forEach(headline => {
            const headlineElements = headline.querySelectorAll('div, a, h1, h2, h3, h4, h5, h6');
            if (headlineElements.length > 0) {
                gsap.to(headlineElements, {
                    delay: 0.1,
                    y: 0,
                    duration: 0.5,
                    opacity: 1,
                    stagger: 0.2,
                    scrollTrigger: {
                        trigger: headline,
                    }
                });
            }
        });
    }


    const projects = gsap.utils.toArray('.project');
    if (projects.length !== 0) {
        projects.forEach(project => {
            gsap.to(project, {
                duration: 0.5,
                clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                ease: "power3.inOut",
                delay: 0.1,
                scrollTrigger: {
                    trigger: project,
                }
            })
        });
    }

    const blogposts = gsap.utils.toArray('.blogpost');
    if (blogposts.length !== 0) {
        blogposts.forEach(blogpost => {
            gsap.to(blogpost, {
                duration: 0.5,
                clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                ease: "power3.inOut",
                stagger: 0.1,
                scrollTrigger: {
                    trigger: blogpost,
                }
            })
        });
    }

    const podcasts = gsap.utils.toArray('.podcasts');
    if (podcasts.length !== 0) {
        podcasts.forEach(podcast => {
            gsap.to(podcast.querySelectorAll('.podcast'), {
                duration: 0.5,
                clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                ease: "power3.inOut",
                stagger: 0.1,
                scrollTrigger: {
                    trigger: podcast,
                }
            })
        });
    }

    const news = gsap.utils.toArray('.news');
    if (news.length !== 0) {
        news.forEach(newsdiv => {
            gsap.to(newsdiv.querySelectorAll('.news-inner'), {
                delay: 0.1,
                clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
                duration: 0.3,
                ease: "power3.inOut",
                stagger: 0.1,
                scrollTrigger: {
                    trigger: newsdiv,
                }
            })
        });
    }



    const carouselButtons = gsap.utils.toArray('.carousel-buttons');
    if (carouselButtons.length !== 0) {
        carouselButtons.forEach(carouselButton => {
            gsap.to(carouselButton.querySelectorAll('button'), {
                y: 0,
                duration: 0.5,
                opacity: 1,
                stagger: 0.2,
                scrollTrigger: {
                    trigger: carouselButton,
                }
            })
        });
    }

    const buttons = gsap.utils.toArray('.buttons');
    if (buttons.length !== 0) {
        buttons.forEach(button => {
            gsap.to(button.querySelectorAll('a'), {
                delay: 0.1,
                y: 0,
                duration: 0.5,
                opacity: 1,
                stagger: 0.2,
                scrollTrigger: {
                    trigger: button,
                }
            })
        });
    }

}


